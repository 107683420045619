import React from 'react'
import { SectionTwoThirdsRight } from './SectionTwoThirdsRight'

const AboutPrinciplesModule = ({ method, principles, selected, selectIndex }) => (
  <div className="mw7-m mw8-l center pv5-ns ph3 pv-1px-s">
    <SectionTwoThirdsRight label="METHOD">{method}</SectionTwoThirdsRight>
    <SectionTwoThirdsRight label="">
      <div className="w-50-ns mt5-ns">
        <div className="flex justify-start">
          {principles.map((principle, i) => {
            return (
              <div key={i}>
                <p
                  className="di mr2 mb0 pointer method-div-trigger gray-hover"
                  style={{ color: selected === i ? '#111' : '#999' }}
                  onClick={selectIndex(i)}
                >
                  0{i + 1}&nbsp;
                </p>
                <p className="di">/&nbsp;</p>
              </div>
            )
          })}
        </div>
        <hr className="o-50" />
        {principles.map((principle, i) => {
          return (
            <div className={`${selected === i ? 'db' : 'dn'}`} key={i}>
              <p style={{ fontSize: '13px' }}>{principle.title}</p>
              <p className="mb0-ns" style={{ fontSize: '13px' }}>
                {principle.description}
              </p>
            </div>
          )
        })}
      </div>
    </SectionTwoThirdsRight>
  </div>
)

export default AboutPrinciplesModule
