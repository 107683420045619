//
// $$$$$$$$\  $$$$$$\   $$$$$$\
// $$  _____|$$  __$$\ $$  __$$\
// $$ |      $$ /  \__|$$ /  \__|
// $$$$$\    $$ |$$$$\ $$ |$$$$\
// $$  __|   $$ |\_$$ |$$ |\_$$ |
// $$ |      $$ |  $$ |$$ |  $$ |
// $$$$$$$$\ \$$$$$$  |\$$$$$$  |
// \________| \______/  \______/
//

// max of how many bands the images will be divided in
const FRACTIONS = 1

// how many images of macco there are
const MACCO_IMAGES_LENGTH = 15

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

function overlapFaces() {
  window.areFacesOverlapped = true

  const team = document.querySelector('#Team')
  const teamImages = [...document.querySelectorAll(team ? '#Team img' : 'img')]
  const columns = window.innerWidth > 960 ? 4 : window.innerWidth > 480 ? 3 : 1

  if (columns === 1) return

  const fractions = FRACTIONS
  const step = 100 / fractions / columns
  const offset = window.overlapIndex
  teamImages.forEach((img, i) => {
    const itemsInRow = Math.min(teamImages.length - Math.floor(i / columns) * columns, columns)
    const column = (i + offset) % itemsInRow

    const path = [...Array(Math.ceil((fractions * columns) / itemsInRow)).keys()]
      .map(j => {
        const start = j * itemsInRow * step + column * step
        const finish = start + step

        return `0% ${start}%, 100% ${start}%, 100% ${finish}%, 0% ${finish}%`
      })
      .join(', ')

    // easeOutExpo
    img.style.transition = 'all 1s cubic-bezier(0.19, 1, 0.22, 1)'

    img.style.clipPath = `polygon(${path})`
    img.style.webkitClipPath = `polygon(${path})`

    const translateX =
      window.innerWidth / 2 - img.getBoundingClientRect().left - img.clientWidth / 2
    img.style.transform = `translate3d(${translateX}px, 0, 0)`

    // move also the triggers
    const sibling = img.nextElementSibling
    if (sibling && sibling.classList.contains('trigger')) {
      sibling.style.transform = `translate3d(${translateX}px, 0, 0)`
    }
  })

  window.overlapIndex++
}

function unOverlapFaces() {
  window.areFacesOverlapped = false

  const team = document.querySelector('#Team')
  const teamImages = [...document.querySelectorAll(team ? '#Team img' : 'img')]
  teamImages.forEach(img => {
    // easeOutBack
    img.style.transition = 'all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)'

    img.style.clipPath = null
    img.style.webkitClipPath = null
    img.style.transform = null

    const sibling = img.nextElementSibling
    if (sibling && sibling.classList.contains('trigger')) {
      sibling.style.transform = null
    }
  })
}

function toggleOverlapFaces() {
  if (window.areFacesOverlapped) {
    unOverlapFaces()
  } else {
    overlapFaces()
  }
}

function shuffleFaces() {
  const team = document.querySelector('#Team')
  const teamImages = [...document.querySelectorAll(team ? '#Team img' : 'img')]
  teamImages.forEach(img => {
    img.previousTop =
      img.getBoundingClientRect().top + (window.pageYOffset || document.body.scrollTop)
    img.previousLeft = img.getBoundingClientRect().left
  })

  const containers = teamImages.map(img => img.parentNode.parentNode)

  shuffleArray(containers)

  const containersContainer = containers[0].parentNode

  const documentFragment = document.createDocumentFragment()
  containers.forEach(c => documentFragment.appendChild(c))

  containersContainer.innerHtml = ''
  containersContainer.appendChild(documentFragment)

  // disable react reveal
  if (!document.querySelector('#disable-react-reveal')) {
    const style = document.createElement('style')
    style.id = 'disable-react-reveal'
    style.innerHTML = `
      .react-reveal {
        animation: none !important;
      }
    `
    document.head.appendChild(style)
  }

  teamImages.forEach(img => {
    const top = img.getBoundingClientRect().top + (window.pageYOffset || document.body.scrollTop)
    const left = img.getBoundingClientRect().left
    img.style.transition = null
    img.style.transform = `translate3d(${img.previousLeft - left}px, ${img.previousTop - top}px, 0)`
  })

  setTimeout(() => {
    teamImages.forEach(img => {
      // easeInOutSine
      img.style.transition = 'all 1s cubic-bezier(0.445, 0.05, 0.55, 0.95)'
      img.style.transform = null
    })
  }, 10)

  // start again from the lower
  window.overlapIndex = 0
}

function startShuffle() {
  if (window.areFacesOverlapped) {
    // force unoverlap of faces
    unOverlapFaces()
    setTimeout(shuffleFaces, 500)
  } else {
    shuffleFaces()
  }
}

function maccoInvasion() {
  const team = document.querySelector('#Team')
  const teamImages = [...document.querySelectorAll(team ? '#Team img' : 'img')]

  const maccos = [...Array(teamImages.length).keys()].map(
    i => `/images/team/maccos/macco-${(i % MACCO_IMAGES_LENGTH) + 1}.jpg`
  )

  shuffleArray(maccos)

  teamImages.forEach((image, i) => {
    image.src = maccos[i]
  })
}

function createTrigger({ width, height, top, bottom, left, right, image, onClick = () => {} }) {
  const trigger = document.createElement('div')
  trigger.classList.add('trigger')
  trigger.style.width = width
  trigger.style.height = height
  trigger.style.top = top
  trigger.style.bottom = bottom
  trigger.style.left = left
  trigger.style.right = right
  trigger.style.cursor = 'pointer'
  trigger.style.position = 'absolute'
  trigger.style.zIndex = '999'
  trigger.addEventListener('click', onClick)
  image.parentNode.appendChild(trigger)

  // for debug
  // trigger.style.background = 'rgba(255, 255, 255, 0.5)'

  // make its container be on top of others
  image.parentNode.parentNode.style.zIndex = 1
  image.parentNode.parentNode.style.position = 'relative'
}

export function initEgg() {
  // how many overlap we do in a row
  window.overlapIndex = 0

  // mariano's shoulder
  createTrigger({
    width: '32%',
    height: '32%',
    bottom: '0',
    left: '0',
    image: document.querySelector('img[src*="mariano_c.jpg"]'),
    onClick: toggleOverlapFaces,
  })

  // jommy's ciuffo
  createTrigger({
    width: '52%',
    height: '35%',
    top: '22px',
    left: '64px',
    image: document.querySelector('img[src*="tom.jpg"]'),
    onClick: shuffleFaces,
  })

  document.addEventListener('keydown', e => {
    if (e.shiftKey && e.key === 'E') {
      toggleOverlapFaces()
    }
    if (e.shiftKey && e.key === 'S') {
      startShuffle()
    }
  })

  window.addEventListener('resize', unOverlapFaces)
}
