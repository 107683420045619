import React from 'react'
import Fade from 'react-reveal/Fade'

const AboutTeamModule = ({ team }) => (
  <div className="bg-white pt4 pt5-ns pb4 bt b--light-gray" id="Team">
    <div className="mw7-m mw8-l center ph3">
      <div className="normal ttu tracked f5 pt0 pb3-ns pb2 fw3 mono">OUR TEAM</div>
      <div className="flex flex-wrap mh-2">
        <Fade>
          {team.map((player, i) => {
            return (
              <div className="w-100 w-33-m w-25-l ph2 mb4 mb4-l" key={i}>
                <div className={`duotone-${i} relative z-1`}>
                  <img
                    className=""
                    alt={player.name + ' ' + player.surname}
                    src={`${player.portrait}`}
                  />
                </div>
                <p className="mv0 f6 pt2">
                  {player.name} {player.surname}
                </p>
                <p className="mt0 mh0 mb2 f6 o-60 o-100-hover">{player.role}</p>
                <p className="mv0">
                  <a
                    className="f6 link near-black underline o-60 o-100-hover"
                    href={`mailto:${player.mail}`}
                  >
                    {player.mail}
                  </a>
                </p>
              </div>
            )
          })}
        </Fade>
      </div>
    </div>
  </div>
)

export default AboutTeamModule
