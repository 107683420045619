import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import AboutPrinciplesModule from '../components/AboutPrinciplesModule'
import AboutTeamModule from '../components/AboutTeamModule'
import { initEgg } from '../utils/easter-egg'

export default class AboutPage extends React.Component {
  state = {
    selected: 0,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      initEgg()
    }
  }

  selectIndex = index => () => {
    this.setState({
      selected: index,
    })
  }

  render() {
    const { data, location } = this.props

    const { selected } = this.state

    const { allAboutJson } = data
    const edges = allAboutJson.edges[0]
    const aboutPage = edges.node
    return (
      <Layout
        type={'home'}
        location={location}
        title="About"
        description="Thanks to our hands-on expertise around visualization challenges and data-heavy projects, we have, over the past 10 years, developed and refined our own design methodology"
      >
        <div className="bg-light-gray">
          <AboutPrinciplesModule
            selected={selected}
            principles={aboutPage.principles}
            method={aboutPage.method}
            selectIndex={this.selectIndex}
          />
          <AboutTeamModule team={aboutPage.team} />
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allAboutJson {
      edges {
        node {
          method
          principles {
            title
            description
          }
          team {
            name
            surname
            role
            mail
            portrait
          }
          collaborators {
            name
            role
          }
        }
      }
    }
  }
`
